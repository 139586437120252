import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import ContentContainer from '../components/UI/ContentContainer';
import PageContentOverlap from '../components/UI/PageContentOverlap';
import PageHeader from '../components/UI/PageHeader';
import { breakpointLarge } from '../styles/breakpoints';

const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 2.5rem;
  font-weight:700;
  padding:1.5rem 0;
`;
const Item = styled.div`
  padding: 0.5rem 1.5rem;
  display: flex;
  @media (min-width: 700px) {
    margin:0 4rem; 
  }
  @media (min-width: ${breakpointLarge}) {
    padding: 0.5rem 2rem;
    max-width: calc(50% - 1rem);
    margin: 0.5rem;
  }
`;
const ItemIcon = styled(Img)`
  width: 57px;
  height:57px;
  @media (min-width: ${breakpointLarge}) {
    width:85px;
    height:85px;
  }
`;
const ItemText = styled.div`
  display:flex;
  align-items:center;
  color: ${props => props.theme.gray};
  p {
    margin: 0.75rem 0;
    text-align:start;
  }
  @media (min-width: ${breakpointLarge}) {
  }
`;
const ContinerIcon = styled.div`
  background-color: ${props => props.theme.gray200};
  border-radius: 1.7rem;
  displey:flex;
  padding: 0.8rem;
  justify-content:center;
  aling-items:center;
  margin-right: 1rem;
  width: 80px;
  height:80px;
  @media (min-width: ${breakpointLarge}) {
    width:130px;
    height:130px;
    padding: 1.2rem;
  }
  
`

export const RequisitosPageTemplate = ({
  image,
  imagePosition,
  title,
  description,
  items,
}) => {
  return (
    <section>
      <Cookies />
      <PageHeader
        image={image}
        backgroundPosition={imagePosition}
        title={title}
        description={description}
      />
      <PageContentOverlap>
        <ContentContainer>
          <Items>
            {items.map(item => (
              <Item>
                
                 <ContinerIcon>
                    <ItemIcon
                      fluid={item.icon.childImageSharp.fluid}
                      imgStyle={{
                        objectFit: 'fill',
                      
                      }}
                    />
                </ContinerIcon>
                <ItemText>
                  <p>{item.description}</p>
                </ItemText>
              </Item>
            ))}
          </Items>
        </ContentContainer>
      </PageContentOverlap>
    </section>
  );
};

RequisitosPageTemplate.propTypes = {
  image: PropTypes.object,
  imagePosition: PropTypes.number,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.object,
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

const RequisitosPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <RequisitosPageTemplate
        image={post.frontmatter.image}
        imagePosition={post.frontmatter.imagePosition}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        items={post.frontmatter.items}
      />
    </Layout>
  );
};

RequisitosPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RequisitosPage;

export const RequisitosPageQuery = graphql`
  query RequisitosPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2018, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imagePosition
        title
        description
        items {
          icon {
            childImageSharp {
              fluid(quality: 75) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          description
        }
      }
    }
  }
`;
