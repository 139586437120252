import styled from 'styled-components';
import PageContent from './PageContent';

const PageContentOverlap = styled(PageContent)`
  margin-top: -6.5rem;
  z-index: 98;
  position: relative;
  
  @media (min-width: 950px) {
    margin-top: -5.8rem;
  }
`;

export default PageContentOverlap;
